export function Button({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const className = `${props.className} font-bold py-2 px-4 rounded bg-blue-500 text-white`;
  return (
    <button {...props} className={className}>
      {children}
    </button>
  );
}
