import { Updater } from "use-immer";
import { DeckBuilderFilter } from "../DeckBuilder/deckBuilder.hook";
import { CardFilterCategorySelect } from "./cardFilter.category";
import { CardFilterSeriesSelect } from "./cardFilter.series";
import { CardFilterCostInput } from "./cardFilter.cost";
import classNames from "classnames";
import { CardFilterPowerInput } from "./cardFilter.power";
import { CardFilterCounterInput } from "./cardFilter.counter";
import { CardFilterColorInput } from "./cardFilter.color";
import { CardFilterRaritySelect } from "./cardFilter.rarity";
import { useEffect, useState } from "react";
import { getCardOption } from "../../client/server";
import { CardFilterFeatureSelect } from "./cardFilter.feature";

export interface ICardFilterBase {
  setFilter: Updater<DeckBuilderFilter>;
}

export interface ICardFilterComponentProp extends ICardFilterBase {
  reset: boolean;
  onReset: () => void;
}

export interface ICardFilterProp extends ICardFilterBase {
  showFilter: boolean;
  setShowFilter: (show: boolean) => void;
}

export const CardFilter: React.FC<ICardFilterProp> = ({
  showFilter,
  setShowFilter,
  setFilter,
}) => {
  const [reset, setIsReset] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [seriesMap, setSeriesMap] = useState<Array<[id: string, name: string]>>(
    []
  );
  const [featureMap, setFeatureMap] = useState<Record<string, number>>({});

  useEffect(() => {
    getCardOption().then((data) => {
      const _fMap: Record<string, number> = {};
      for (const d of data.features) {
        _fMap[d[0]] = d[1];
      }

      setFeatureMap(_fMap);
      setSeriesMap(data.series);
      setIsLoading(false);
    });
  }, []);

  function resetFilter() {
    setIsReset(true);
  }

  return (
    <>
      <div
        className={classNames("absolute top-0 w-full h-screen bg-white", {
          hidden: !showFilter,
          "border-r-2": 1,
        })}
      >
        <div className="absolute right-4 top-2">
          <button
            className="font-bold py-2 px-4 rounded bg-red-500 text-white"
            onClick={() => resetFilter()}
            type="button"
          >
            Reset
          </button>
        </div>

        <form className="px-2 pb-16">
          <CardFilterSeriesSelect
            setFilter={setFilter}
            seriesMap={seriesMap}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterCategorySelect
            setFilter={setFilter}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterRaritySelect
            setFilter={setFilter}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterColorInput
            setFilter={setFilter}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterCostInput
            setFilter={setFilter}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterPowerInput
            setFilter={setFilter}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterCounterInput
            setFilter={setFilter}
            reset={reset}
            onReset={() => setIsReset(false)}
          />
          <CardFilterFeatureSelect
            setFilter={setFilter}
            featureMap={featureMap}
            reset={reset}
            onReset={() => setIsReset(false)}
          />

          <div className="w-full text-center pt-4">
            <button
              className="font-bold py-2 px-4 rounded bg-blue-500 text-white"
              onClick={() => setShowFilter(false)}
              type="button"
            >
              Update Filter
            </button>
          </div>
        </form>
      </div>

      <div className="py-4 text-center">
        <button
          className="font-bold py-2 px-4 rounded bg-blue-500 text-white"
          onClick={() => !isLoading && setShowFilter(true)}
        >
          Filter
        </button>
      </div>
    </>
  );
};
