import { useEffect, useState } from "react";
import { ICardFilterComponentProp } from "./cardFilter";

export const CardFilterCostInput: React.FC<ICardFilterComponentProp> = ({
  reset,
  onReset,
  setFilter,
}) => {
  const MAX_COST = 10;
  const [cost, setCost] = useState<[number, number]>([0, MAX_COST]);

  useEffect(() => {
    if (reset) {
      setCost([0, MAX_COST]);
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((prev) => {
      prev.cost = cost;
    });
  }, [cost]);

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">Cost</div>
      <select
        id="cost-min"
        className="border w-12"
        value={cost[0]}
        onChange={(e) => {
          setCost([parseInt(e.target.value), cost[1]]);
        }}
      >
        {[...Array(cost[1] + 1)].map((_, index) => {
          return <option value={index}>{index}</option>;
        })}
      </select>
      <span className="px-1"></span>
      <select
        id="cost-max"
        className="border w-12"
        value={cost[1]}
        onChange={(e) => {
          const max = parseInt(e.target.value);
          setCost([Math.min(cost[0], max), max]);
        }}
      >
        {[...Array(MAX_COST + 1)].map((_, index) => {
          return <option value={index}>{index}</option>;
        })}
      </select>
    </div>
  );
};
