import classNames from "classnames";
import { Card } from "../../../model/card";
import { CardBlock } from "../../../component/cardblock";
import { CARD_CATEGORY } from "../../../constant";

export interface ICardViewProp {
  className?: string;
  card?: Card;
  amountInDeck: number;
  onClose?: () => void;
  addCard?: (card: Card) => void;
  removeCard?: (card: Card) => void;
}

const CardView: React.FC<ICardViewProp> = ({
  className,
  card,
  amountInDeck,
  onClose,
  addCard,
  removeCard,
}) => {
  if (!card) {
    return <></>;
  }

  const cardViewDetailCell = (
    title: string,
    content: string | Array<string>,
    fullWidth?: boolean
  ) => {
    if (typeof content === "string") {
      content = [content];
    }

    return (
      <div
        className={classNames("py-1", {
          "w-full": fullWidth,
          "flex w-1/2": !fullWidth,
        })}
      >
        <div className="w-24 shrink-0">{title}</div>
        <div className="flex flex-col gap-2">
          {content.map((text, i) => {
            return <div key={`effect-${i}`}>{text}</div>;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full">
      <div className={classNames(className, "flex gap-4 p-4")}>
        <div className="w-1/5">
          <CardBlock card={card} />
        </div>

        <div className="flex flex-col max-w-xs">
          <div className="flex flex-wrap">
            <div className="pb-4 w-full font-bold">
              <div className="text-2xl">{card.cardName}</div>
              <div className="">{card.id}</div>
            </div>
            {cardViewDetailCell("Cost", card.cost.toString())}
            {card.attribute && cardViewDetailCell("Attribute", card.attribute)}
            {!!card.power && cardViewDetailCell("Power", card.power.toString())}
            {!!card.counter &&
              cardViewDetailCell("Counter", card.counter.toString())}
            {cardViewDetailCell("Color", card.color)}
            {cardViewDetailCell("Rarity", card.rarity)}
            {cardViewDetailCell("Feature", card.feature, true)}
          </div>

          <div className="grow"></div>
          {card.text && cardViewDetailCell("Text", JSON.parse(card.text), true)}
          {card.trigger && cardViewDetailCell("Trigger", card.trigger, true)}
          {cardViewDetailCell("Soruce", card.getInfo, true)}
        </div>

        <div className="grow flex gap-4 py-4 justify-center items-center">
          {card.category !== CARD_CATEGORY.LEADER && (
            <>
              <button
                className="rounded w-24 h-10 bg-green-500"
                onClick={() => addCard && addCard(card)}
              >
                Add
              </button>
              {amountInDeck}
              <button
                className="rounded w-24 h-10 bg-red-500"
                onClick={() => removeCard && removeCard(card)}
              >
                Remove
              </button>
            </>
          )}

          {card.category === CARD_CATEGORY.LEADER && (
            <button
              className="rounded w-24 h-10 bg-green-500"
              onClick={() => {
                addCard && addCard(card);
                onClose && onClose();
              }}
            >
              Use
            </button>
          )}
        </div>
      </div>

      <div
        className="grow bg-black/75"
        onClick={() => {
          onClose && onClose();
        }}
      ></div>
    </div>
  );
};

export default CardView;
