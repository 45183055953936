import { useEffect, useState } from "react";
import { ICardFilterComponentProp } from "./cardFilter";

export const CardFilterPowerInput: React.FC<ICardFilterComponentProp> = ({
  reset,
  onReset,
  setFilter,
}) => {
  const MAX_POWER = 13000;
  const [power, setPower] = useState<[number, number]>([0, MAX_POWER]);

  useEffect(() => {
    if (reset) {
      setPower([0, MAX_POWER]);
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((prev) => {
      prev.power = power;
    });
  }, [power]);

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">Power</div>
      <select
        id="power-min"
        className="border w-20"
        value={power[0]}
        onChange={(e) => {
          setPower([parseInt(e.target.value), power[1]]);
        }}
      >
        {[...Array(power[1] / 1000 + 1)].map((_, index) => {
          return <option value={index * 1000}>{index * 1000}</option>;
        })}
      </select>
      <span className="px-1"></span>
      <select
        id="power-max"
        className="border w-20"
        value={power[1]}
        onChange={(e) => {
          const max = parseInt(e.target.value);
          setPower([Math.min(power[0], max), max]);
        }}
      >
        {[...Array((MAX_POWER + 1000) / 1000)].map((_, index) => {
          return <option value={index * 1000}>{index * 1000}</option>;
        })}
      </select>
    </div>
  );
};
