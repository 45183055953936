import React, { useMemo } from "react";
import { Card } from "../../model/card";
import { CardInDeck } from "../DeckBuilder/deckBuilder.enity";
import { CardBlock } from "../../component/cardblock";
import { DeckListPanel } from "./panel/Decklist.panel";

interface IDeckListProp {
  leader?: Card;
  deck: Record<string, CardInDeck>;
  onClickCard?: (card: Card) => void;
  onClickSaveDeck?: (name?: string) => void;
}

const DeckList: React.FC<IDeckListProp> = ({
  leader,
  deck,
  onClickCard,
  onClickSaveDeck,
}) => {
  const sortedDeckKey = useMemo(() => {
    return Object.keys(deck).sort((cardA, cardB) => {
      return deck[cardA].cost > deck[cardB].cost ? 1 : -1;
    });
  }, [deck]);

  const renderDeckCard = () => {
    function renderCardBlock(card: CardInDeck) {
      const result = [];
      for (let i = 0; i < card.count; i++) {
        result.push(
          <CardBlock
            key={`deck-${card.uid}-${i}`}
            className="w-1/5 2xl:w-[10%]"
            card={card}
            onClickCard={onClickCard}
          />
        );
      }

      return result;
    }

    const result = [];
    for (const uid of sortedDeckKey) {
      const card = deck[uid];
      for (const cardBlock of renderCardBlock(card)) {
        result.push(cardBlock);
      }
    }

    return result;
  };

  return (
    <div className="flex flex-col h-full">
      <div className="h-56 grow-0 flex w-full">
        <div className="h-full">
          <CardBlock
            className="h-full"
            card={leader}
            onClickCard={onClickCard}
          />
        </div>
        <div className="grow bg-slate-100">
          <DeckListPanel deck={deck} onSaveDeck={onClickSaveDeck} />
        </div>
      </div>

      <div className="flex flex-wrap">{renderDeckCard()}</div>
    </div>
  );
};

export default DeckList;
