import { CardInDeck } from "../../DeckBuilder/deckBuilder.enity";
import { DeckListCostChart } from "./Decklist.panel.chart";
import { Tag } from "../../../component/Tag";
import { useDeckListData } from "./Decklist.data";
import { useState } from "react";

interface IDeckListPanel {
  deck: Record<string, CardInDeck>;
  onSaveDeck?: (name?: string) => void;
}

export const DeckListPanel: React.FC<IDeckListPanel> = ({
  deck,
  onSaveDeck,
}) => {
  const [deckName, setDeckName] = useState<string>("");
  const { deckCostDistrubtion, deckTotal, counterCount, deckTagMap } =
    useDeckListData(deck);

  return (
    <div className="p-2 h-full flex flex-col">
      <div className="w-full flex items-center gap-2">
        <div className="grow">
          <input
            className="w-full h-full border-2 p-2"
            maxLength={30}
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
          />
        </div>
        <button
          className="font-bold py-2 px-4 rounded bg-blue-500 text-white"
          onClick={() => onSaveDeck && onSaveDeck(deckName)}
        >
          Save
        </button>
        <a
          href="/list"
          className="font-bold py-2 px-4 rounded bg-blue-500 text-white"
        >
          Saved Deck
        </a>
      </div>
      <div className="grow"></div>
      <div className="w-full flex flex-col gap-2">
        <div className="flex gap-4">
          <DeckListCostChart costDistrubtion={deckCostDistrubtion} />
          <div className="flex flex-col gap-2">
            <div className="flex">
              <Tag>Deck: {deckTotal}</Tag>
              <Tag>1000 Counter: {counterCount[1000]}</Tag>
              <Tag>2000 Counter: {counterCount[2000]}</Tag>
            </div>
            <div className="flex-wrap">
              {deckTagMap.map((feature) => {
                return <Tag>{`${feature.name} ${feature.count}`}</Tag>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
