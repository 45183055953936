import axios from "axios";
import { CARD_CATEGORY, SERVER_ENDPOINT } from "../constant";
import { Card } from "../model/card";
import { CardListFilter, CardOption } from "./server.dto";

const instance = axios.create({
  baseURL: SERVER_ENDPOINT,
  timeout: 10000,
});

export const getCardList = async (
  cardListFilter: CardListFilter
): Promise<Array<Card>> => {
  const data = await instance.post<Array<Card>>("cardlist", {
    sid: cardListFilter.sid,
    category: cardListFilter.category,
    cost: cardListFilter.cost,
    power: cardListFilter.power,
    counter: cardListFilter.counter,
    color: cardListFilter.color,
    rarity: cardListFilter.rarity,
    feature: cardListFilter.feature,
  });
  return data.data;
};

export const getCardOption = async (): Promise<CardOption> => {
  const data = await instance.get<CardOption>("option");
  return data.data;
};

export const getCard = async (uid: Array<string>): Promise<Array<Card>> => {
  const data = await instance.post<Array<Card>>("card", { uid });
  return data.data;
};

export const getDeck = async (
  deckId: string
): Promise<Record<string, number>> => {
  const data = await instance.get<Record<string, number>>(`deck/${deckId}`);
  return data.data;
};

export const saveDeck = async (
  deck: Record<string, number>
): Promise<string> => {
  const data = await instance.post<string>("deck", deck);
  return data.data;
};
