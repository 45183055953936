import { useImmer } from "use-immer";
import { ICardFilterComponentProp } from "./cardFilter";
import { useEffect, useState } from "react";
import classNames from "classnames";

interface IFeatureSelectProp extends ICardFilterComponentProp {
  featureMap: Record<string, number>;
}

export const CardFilterFeatureSelect: React.FC<IFeatureSelectProp> = ({
  reset,
  onReset,
  setFilter,
  featureMap,
}) => {
  const [showFullList, setShowFullList] = useState(false);
  const [values, setValues] = useImmer<Set<string>>(new Set<string>());

  useEffect(() => {
    if (reset) {
      setValues(prev=>prev.clear());
      setShowFullList(false);
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((filter) => {
      filter.feature = Array.from(values);
    });
  }, [values]);

  function onClickHanlder(rare: string) {
    setValues((prev) => {
      if (prev.has(rare)) {
        prev.delete(rare);
      } else {
        prev.add(rare);
      }
    });
  }

  function renderOptions(
    min: number | undefined = undefined,
    max: number | undefined = undefined
  ) {
    return Object.keys(featureMap).map((feature) => {
      if (min && featureMap[feature] < min) {
        return null;
      }
      if (max && featureMap[feature] > max) {
        return null;
      }

      const hasValue = values.has(feature);
      return (
        <div
          key={`FEATURE-${feature}`}
          onClick={() => onClickHanlder(feature)}
          className={classNames("px-4 w-18 rounded-full", {
            "bg-blue-200": !hasValue,
            "bg-blue-400": hasValue,
          })}
        >
          <input type="checkbox" checked={hasValue} readOnly />
          <label className="px-1">
            {feature} ({featureMap[feature]})
          </label>
        </div>
      );
    });
  }

  return (
    <div className="py-4">
      <div className="">Feature</div>
      <div className="flex flex-wrap gap-2 pt-4">
        {renderOptions(10)}
        {!showFullList && <div>...</div>}
        {showFullList && renderOptions(0, 9)}
        <div className="w-full pt-2 text-center">
          <button
            className="grow-0 font-bold text-gray-500 text-sm"
            onClick={() => setShowFullList(!showFullList)}
            type="button"
          >
            {showFullList ? "Show less" : "Show more"}
          </button>
        </div>
      </div>
    </div>
  );
};
