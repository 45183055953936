const STORAGE_KEY = "deckStorage";

interface DeckStorageData {
  id: string;
  name?: string;
  coverUrl?: string;
}

const deckStorage: Record<string, DeckStorageData> = JSON.parse(
  localStorage.getItem(STORAGE_KEY) ?? "{}"
);

function createDeckStorageData(data: DeckStorageData) {
  return {
    ...data,
    createdAt: Date.now(),
  };
}

export function getDeckStorage() {
  return deckStorage;
}

export function storeDeck(input: DeckStorageData) {
  const processedInput = {
    ...input,
    name: input.name ? input.name : `Unnamed Deck - ${input.id.split("-")[0]}`,
  };
  const data = createDeckStorageData(processedInput);
  deckStorage[input.id] = data;
  localStorage.setItem(STORAGE_KEY, JSON.stringify(deckStorage));
}

export function deleteDeck(id: string) {
  delete deckStorage[id];
  localStorage.setItem(STORAGE_KEY, JSON.stringify(deckStorage));
}
