import { useMemo } from "react";
import { CardInDeck } from "../../DeckBuilder/deckBuilder.enity";

export function useDeckListData(deck: Record<string, CardInDeck>) {
  const deckCostDistrubtion = useMemo<Record<number, number>>(() => {
    const data: Record<number, number> = {};
    for (const id in deck) {
      const card = deck[id];
      data[card.cost] = (data[card.cost] ?? 0) + card.count;
    }

    return data;
  }, [deck]);

  const deckTotal = useMemo<number>(() => {
    let total: number = 0;
    for (const id in deck) {
      const card = deck[id];
      total += card.count;
    }

    return total;
  }, [deck]);

  const counterCount = useMemo<Record<1000 | 2000, number>>(() => {
    const total: Record<number, number> = {
      1000: 0,
      2000: 0,
    };
    for (const id in deck) {
      const card = deck[id];
      total[card.counter] += card.count;
    }

    return total;
  }, [deck]);

  const deckTagMap = useMemo<{ name: string; count: number }[]>(() => {
    const map: Record<string, number> = {};
    for (const id in deck) {
      const card = deck[id];
      const features = card.feature.split("/");
      features.forEach((feature) => {
        map[feature] = map[feature] ?? 0;
        map[feature] += card.count;
      });
    }

    const result = Object.keys(map)
      .map((feature) => {
        return {
          name: feature,
          count: map[feature],
        };
      })
      .sort((a, b) => {
        return b.count - a.count;
      });

    return result;
  }, [deck]);

  return { deckCostDistrubtion, deckTotal, counterCount, deckTagMap };
}
