import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import DeckBuilder from "./module/DeckBuilder/deckBuilder";
import { SavedDeck } from "./module/SavedDeck/SavedDeck";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/list" element={<SavedDeck />} />
        <Route path="/" element={<DeckBuilder />}>
          <Route path=":id" element={<DeckBuilder />} />
        </Route>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
