import { Card } from "../../model/card"
import { CardBlock } from "../../component/cardblock";


interface ICardListProp {
  data?: Array<Card>
  onClickCard: (card: Card) => void
}

const CardList: React.FC<ICardListProp> = ({ data, onClickCard }) => {
  if (!data) {
    return <div>"Loading"</div>
  }

  return (
    <div className="flex flex-wrap">
      {
        data.map((card) => {
          return <CardBlock className="w-1/4" key={card.uid} card={card} onClickCard={onClickCard} />
        })
      }
    </div>
  );
}

export default CardList;


