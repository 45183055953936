import { useEffect, useState } from "react";
import { CARD_CATEGORY } from "../../constant";
import { ICardFilterComponentProp } from "./cardFilter";

export const CardFilterCategorySelect: React.FC<ICardFilterComponentProp> = ({
  reset,
  onReset,
  setFilter,
}) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (reset) {
      setValue("");
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((prev) => {
      const category = value as CARD_CATEGORY;
      prev.category = [category].filter((ele) => !!ele);
    });
  }, [value]);

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">CATEGORY</div>
      <select
        id="category"
        className="border"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        <option value={""}>ALL</option>
        {Object.keys(CARD_CATEGORY).map((category) => {
          return (
            <option key={`CARD_CATEGORY-${category}`} value={category}>
              {category}
            </option>
          );
        })}
      </select>
    </div>
  );
};
