import * as serverClient from "../client/server";
import { CardListFilter } from "../client/server.dto";

export const getCardList = async (cardListFilter: CardListFilter) => {
  const data = await serverClient.getCardList(cardListFilter);
  for (const card of data) {
    card.attribute = card.attribute === "-" ? null : card.attribute;
    card.text = card.text === "null" ? null : card.text;
  }
  return data;
};

export const getCard = async (uid: Array<string>) => {
  const data = await serverClient.getCard(uid);
  for (const card of data) {
    card.attribute = card.attribute === "-" ? null : card.attribute;
    card.text = card.text === "null" ? null : card.text;
  }
  return data;
};
