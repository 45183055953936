import { useImmer } from "use-immer";
import { ICardFilterComponentProp } from "./cardFilter";
import { useEffect } from "react";
import classNames from "classnames";

const colorMap = ["紅", "綠", "藍", "紫", "黑", "黃"];

export const CardFilterColorInput: React.FC<ICardFilterComponentProp> = ({
  reset,
  onReset,
  setFilter,
}) => {
  const [values, setValues] = useImmer<Set<string>>(new Set<string>());

  useEffect(() => {
    if (reset) {
      setValues(prev=>prev.clear());
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((filter) => {
      filter.color = Array.from(values);
    });
  }, [values]);

  function onClickHanlder(rare: string) {
    setValues((prev) => {
      if (prev.has(rare)) {
        prev.delete(rare);
      } else {
        prev.add(rare);
      }
    });
  }

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">Color</div>
      <div className="flex flex-wrap gap-2">
        {colorMap.map((color) => {
          const hasValue = values.has(color);
          return (
            <div
              key={`COLOR-${color}`}
              onClick={() => onClickHanlder(color)}
              className={classNames("px-4 w-18 rounded-full", {
                "bg-blue-200": !hasValue,
                "bg-blue-400": hasValue,
              })}
            >
              <input type="checkbox" checked={hasValue} readOnly />
              <label className="px-1">{color}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
