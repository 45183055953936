import { useState } from "react";
import { Card } from "../../model/card";
import CardList from "../CardList/cardList";
import DeckList from "../DeckList/DeckList";
import { useDeckBuilder } from "./deckBuilder.hook";
import CardView from "./component/deckBuilder.cardView";
import { CardFilter } from "../CardFilter/cardFilter";

function DeckBuilder() {
  const { cardList, leader, deck, addCard, removeCard, setFilter, saveDeck } =
    useDeckBuilder();
  const [cardViewTarget, setCardViewTarget] = useState<Card>();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const onClickCard = (card: Card) => {
    setCardViewTarget(card);
  };

  return (
    <div className="w-full h-full flex">
      <div className="w-2/5 max-h-screen shrink-0 overflow-auto relative">
        <CardFilter
          setFilter={setFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
        {!showFilter && <CardList data={cardList} onClickCard={onClickCard} />}
      </div>
      <div className="w-3/5 max-h-screen shrink-0 overflow-auto relative">
        <DeckList
          leader={leader}
          deck={deck}
          onClickCard={setCardViewTarget}
          onClickSaveDeck={saveDeck}
        />

        {cardViewTarget && (
          <div className="fixed top-0 bottom-0 flex flex-col w-[inherit]">
            <CardView
              className="bg-white"
              card={cardViewTarget}
              addCard={addCard}
              removeCard={removeCard}
              onClose={() => setCardViewTarget(undefined)}
              amountInDeck={
                cardViewTarget && deck[cardViewTarget.uid]
                  ? deck[cardViewTarget.uid].count
                  : 0
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DeckBuilder;
