import classNames from "classnames";
import { ICardFilterBase, ICardFilterComponentProp } from "./cardFilter";
import { useImmer } from "use-immer";
import { useEffect } from "react";

const rarityMap: Record<string, string> = {
  SP: "SP卡",
  SEC: "SEC",
  SR: "SR",
  R: "R",
  UC: "UC",
  C: "C",
};

export const CardFilterRaritySelect: React.FC<ICardFilterComponentProp> = ({
  reset,
  onReset,
  setFilter,
}) => {
  const [values, setValues] = useImmer<Set<string>>(new Set<string>());
  
  useEffect(() => {
    if (reset) {
      setValues((prev) => prev.clear());
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((filter) => {
      filter.rarity = Array.from(values);
    });
  }, [values]);

  function onClickHanlder(rare: string) {
    setValues((prev) => {
      if (prev.has(rare)) {
        prev.delete(rare);
      } else {
        prev.add(rare);
      }
    });
  }

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">Rarity</div>
      <div className="flex flex-wrap gap-2">
        {Object.keys(rarityMap).map((rare) => {
          const hasValue = values.has(rare);
          return (
            <div
              key={`RARITY-${rare}`}
              onClick={() => onClickHanlder(rare)}
              className={classNames("px-4 w-18 rounded-full", {
                "bg-blue-200": !hasValue,
                "bg-blue-400": hasValue,
              })}
            >
              <input type="checkbox" checked={hasValue} readOnly />
              <label className="px-1">{rare}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
