import { useEffect, useState } from "react";
import { ICardFilterBase, ICardFilterComponentProp } from "./cardFilter";
import classNames from "classnames";

export const CardFilterCounterInput: React.FC<ICardFilterComponentProp> = ({
  reset,
  onReset,
  setFilter,
}) => {
  const [cost, setCost] = useState<number>(-1);

  useEffect(() => {
    if (reset) {
      setCost(-1);
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((prev) => {
      prev.counter = cost < 0 ? undefined : cost;
    });
  }, [cost]);

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">Counter</div>
      <div className="flex flex-wrap gap-2">
        {[0, 1000, 2000].map((counter) => {
          const hasValue = cost === counter;
          return (
            <div
              key={`COLOR-${counter}`}
              onClick={() => setCost(hasValue ? -1 : counter)}
              className={classNames("px-4 w-18 rounded-full", {
                "bg-blue-200": !hasValue,
                "bg-blue-400": hasValue,
              })}
            >
              <input type="checkbox" checked={hasValue} readOnly />
              <label className="px-1">{counter}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
