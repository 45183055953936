export function NavigationButton({
  children,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const className = `${props.className} font-bold py-2 px-4 rounded bg-blue-500 text-white`;
  return (
    <a {...props} className={className}>
      {children}
    </a>
  );
}
