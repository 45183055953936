import { useEffect, useState } from "react";
import { ICardFilterComponentProp } from "./cardFilter";

interface ISeriesSelectProp extends ICardFilterComponentProp {
  seriesMap: Array<[id: string, name: string]>;
}

export const CardFilterSeriesSelect: React.FC<ISeriesSelectProp> = ({
  reset,
  onReset,
  setFilter,
  seriesMap,
}) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (reset) {
      setValue("");
      onReset();
    }
  }, [reset]);

  useEffect(() => {
    setFilter((prev) => {
      prev.sid = value;
    });
  }, [value]);

  return (
    <div className="flex py-4">
      <div className="w-40 shrink-0">SERIES</div>
      <select
        value={value}
        id="series"
        className="border"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        <option value={""}>ALL</option>
        {seriesMap.map((seriesData) => {
          return (
            <option key={`SERIES-${seriesData[0]}`} value={seriesData[0]}>
              {seriesData[1]}
            </option>
          );
        })}
      </select>
    </div>
  );
};
