import { Button } from "../../component/Button/Button";
import { NavigationButton } from "../../component/Button/NavigationButton";
import { CardBlock, imgEndpoint } from "../../component/cardblock";
import { deleteDeck, getDeckStorage } from "../../service/deckStorageService";

export function SavedDeck() {
  const deckStorage = getDeckStorage();

  return (
    <div className="container mx-auto py-4 flex flex-col gap-4">
      <div>
        <NavigationButton href="/" className="grow-0">
          Home
        </NavigationButton>
      </div>
      <div className="grid grid-cols-6">
        {Object.keys(deckStorage).map((id) => {
          const deck = deckStorage[id];
          const cardCover = !deck.coverUrl ? (
            <CardBlock />
          ) : (
            <div className="aspect-[600/838]">
              <img src={imgEndpoint + deck.coverUrl} />
            </div>
          );

          return (
            <div className="p-2 grow-0">
              <div className="flex flex-col border-2 rounded ">
                {cardCover}
                <div className="p-2">
                  <div className="pb-2 overflow-ellipsis overflow-hidden whitespace-nowrap">
                    {deck.name}
                  </div>
                  <div className="flex gap-2">
                    <NavigationButton
                      href={`/${deck.id}`}
                      className="grow text-center"
                    >
                      Open
                    </NavigationButton>
                    <Button onClick={() => deleteDeck(deck.id)}>X</Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
